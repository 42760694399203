import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle` 
::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(71, 82, 92, 0.3);
            box-shadow: inset 0 0 6px rgba(71, 82, 92, 0.3);
    background-color: ${({ theme }) => theme.colors.background};
}

::-webkit-scrollbar
{
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.colors.scroll};
}

::-webkit-scrollbar-thumb
{
    background-color:${({ theme }) => theme.colors.scroll};
    border-radius: 20px;
}
`;

export default GlobalStyles;