const firebaseConfig = {
    apiKey: "AIzaSyB5rlI-aY1rGzY3TnlNTOX_pDVeBDP84tQ",
    authDomain: "mbarizao-portfolio.firebaseapp.com",
    projectId: "mbarizao-portfolio",
    storageBucket: "mbarizao-portfolio.appspot.com",
    messagingSenderId: "964617973946",
    appId: "1:964617973946:web:56fc03423c5252e2fbd283",
    measurementId: "G-BRTT800EZQ"
  };

export default firebaseConfig;